<template>
    <div>
        <h3>Account Balances</h3>
        <vs-card class="mt-4">
            <div>
                <div class="d-flex justify-content-between pt-4 pl-4 pr-4">
                    <div class="text-muted">
                        <h5 class="">Current Balance</h5>
                        <h2 class="mt-2">{{currentBalance}}</h2>
                    </div>
                    <div class="float-right">
                        <vs-button type="border">Add Funds</vs-button>
                    </div>
                </div>
                <div class="m-0 text-muted p-4">
                    <p class="lead">You can add funds to your wallet account to process refunds and even make transfers when your balance go low. You can add even large amount to your account without hassle.</p>
                </div>
            </div>
        </vs-card>
        <p class="mt-4 mb-4">Note: Standard charges applies when funding your account.</p>
        <vs-card>
            <div>
                <div class="d-flex justify-content-between pt-4 pl-4 pr-4">
                    <div class="text-muted">
                        <h5 class="">Reserve Balance</h5>
                        <h2 class="mt-2">{{currentBalance}}</h2>
                    </div>
                    <div class="float-right">
                        <vs-button type="border">Add Funds</vs-button>
                    </div>
                </div>
                <div class="m-0 text-muted p-4">
                    <p class="lead">You can add funds to your wallet account to process refunds and even make transfers when your balance go low. You can add even large amount to your account without hassle.</p>
                </div>
            </div>
        </vs-card>
        <vs-card class="mt-4">
            <div>
                <div class="d-flex justify-content-between pt-4 pl-4 pr-4">
                    <div class="text-muted">
                        <h5 class="">Bonus Balance</h5>
                        <h2 class="mt-2">{{currentBalance}}</h2>
                    </div>
                    <div class="float-right">
                        <vs-button type="border">Add Funds</vs-button>
                    </div>
                </div>
                <div class="m-0 text-muted p-4">
                    <p class="lead">You can add funds to your wallet account to process refunds and even make transfers when your balance go low. You can add even large amount to your account without hassle.</p>
                </div>
            </div>
        </vs-card>
    </div>
</template>

<script>
export default {
    name: 'Balance',
    data() {
        return {
            currentBalance: '₦500,000'
        }
    }
}
</script>
